import '../../app/css/pages/app-invoice.css';
import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import flatpickr from "flatpickr";
import select2 from 'select2';
import repeater from "jquery.repeater";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButton_45f4fbef(event) {
    let form = $("#invoice-based-stock-input-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSubmitForm_45f4fbef(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();

    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('input');

    inputs.prop('disabled', true);
    fd.append('products', JSON.stringify(getTableData()));
    fd.append('additional-costs', JSON.stringify(getAdditionalCosts()));

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            form.trigger("reset");
            form.removeClass("was-validated");

            $('#partner_id').val('').trigger('change');
            $('#investor_group').val('').trigger('change');
            $('#investor_id').val('').trigger('change');

            $('#partner_vat').text('');
            $('#partner_address').text('');
            $('#partner_country').text('');

            $('.cost-repeater .repeater-wrapper:not(:first)').remove();
            $('.product-repeater .repeater-wrapper:not(:first)').remove();
        } else {
            swalError(response.data);
        }
    }).fail(function (e) {
        swalUnknown(e);
    }).always(function () {
        inputs.prop('disabled', false);
        toggleLoading('#saveButton', '#loadingButton');
    });
}

function onDocumentPartnerSelect_45f4fbef(e) {
    let data = e.params.data;

    $('#partner_vat').text(data.vat);
    $('#partner_address').text(data.address);
    $('#partner_country').text(data.zip + ' ' + data.city + ', ' + data.country);
}

function onInvestorChange_45f4fbef() {
    let helper = $('#helper');
    let investor = $('#investor_id');

    investor.prop('disabled', false);
    investor.find('option').remove();

    $.ajax({
        url: helper.data('investors-list'),
        type: 'POST',
        data: {"ID": $('#investor_group').val()},
        async: true
    }).done(function (response) {
        if (response.success) {
            $.each(response.data, function (key, value) {
                investor.append($("<option></option>").attr("value", key).text(value));
            });
        }
    });
}

function onDocumentProductSelect_45f4fbef(e) {
    let data = e.params.data;
    let digits = getDigits();
    let target = $(e.target).parents('.product-details-border');

    target.find('[aria-describedby="product_sku"]').val(data.SKU);
    target.find('[aria-describedby="product_barcode"]').val(data.barcode);
    target.find('.container-info').text(data.container_info);
    target.find('[aria-describedby="product_container"]').val(data.container_num);
    target.find('[aria-describedby="product_vat_rate"]').val(data.vat_id);
    target.find('[aria-describedby="product_on_stock"]').val(data.on_stock);
    target.find('[aria-describedby="product_avg_net"]').val(data.avg_net_price_num.toFixed(digits));
    target.find('[aria-describedby="product_avg_gross"]').val(data.avg_gross_price_num.toFixed(digits));
}

function onCurrencyChange_45f4fbef() {
    $('.currency').each(function () {
        $(this).text($('#currency').val());
    });
}

function onChangeGrossPrice_45f4fbef(event) {
    let digits = getDigits();
    let current = parseFloat($(event.target).val());
    let count = parseInt($(event.target).parents('.product-details-border').find('[aria-describedby="product_count"]').val()) || 0;
    let vatRate = parseFloat($(event.target).parents('.product-details-border').find('[aria-describedby="product_vat_rate"] option:selected').data('rate')) / 100 + 1;

    $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_unit_price"]').val((current / vatRate).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_vat"]').val((current - current / vatRate).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_total"]').val(((current / vatRate) * count).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_total"]').val((current * count).toFixed(digits));
}

function onChangeNetPrice_45f4fbef(event) {
    let digits = getDigits();
    let current = parseFloat($(event.target).val());
    let count = parseInt($(event.target).parents('.product-details-border').find('[aria-describedby="product_count"]').val()) || 0;
    let vatRate = parseFloat($(event.target).parents('.product-details-border').find('[aria-describedby="product_vat_rate"] option:selected').data('rate')) / 100 + 1;

    $(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_unit_price"]').val((current * vatRate).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_vat"]').val((current * vatRate - current).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_total"]').val((current * count).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_total"]').val((current * vatRate * count).toFixed(digits));
}

function onChangeVatRate_45f4fbef(event) {
    let digits = getDigits();
    let rate = parseFloat($(event.target).parents('.product-details-border').find('[aria-describedby="product_vat_rate"] option:selected').data('rate')) / 100 + 1;
    let count = parseInt($(event.target).parents('.product-details-border').find('[aria-describedby="product_count"]').val()) || 0;
    let grossPrice = parseFloat($(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_unit_price"]').val());
    let netPrice = $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_unit_price"]');

    netPrice.val((grossPrice / rate).toFixed(digits));

    $(event.target).parents('.product-details-border').find('[aria-describedby="product_vat"]').val((grossPrice - parseFloat(netPrice.val())).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_total"]').val((parseFloat(netPrice.val()) * count).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_total"]').val((grossPrice * count).toFixed(digits));
}

export function initInvoiceBasedInputPage() {
    window.flatpickr = flatpickr;
    window.repeater = repeater;
    window.select2 = select2;

    let html = $('html');
    let helper = $('#helper');

    if (html.attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    window.formatProduct = function (product) {
        if (product.loading) {
            return product.text;
        }

        return "<div class='select2-result-repository clearfix d-flex'>" +
            "<div class='select2-result-repository__avatar mr-2'>" +
            "<img alt='product' src='" + helper.data('assets') + "/img/product/" + product.image_url + "' class='width-2 height-2 mt-1 rounded' />" +
            "</div>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title fs-lg fw-500'>" + product.product_name + "</div>" +
            "<div class='select2-result-repository__statistics d-flex font-small-3'>" +
            "<div class='select2-result-repository__forks mr-2'><b>" + helper.data('sku') + ":</b> " + product.SKU + "</div>" +
            "<div class='select2-result-repository__stargazers mr-2'><b>" + helper.data('cost') + ":</b> " + product.avg_gross_price + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('container') + ":</b> " + product.container_info + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('stock') + ":</b> " + product.on_stock + " " + helper.data('abbr') + "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatProductSelection = function (product) {
        return product.product_name || product.text;
    };

    window.formatPartner = function (product) {
        if (product.loading) {
            return product.text;
        }

        return "<div class='select2-result-repository clearfix d-flex'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title fs-lg fw-500'>" + product.name + "</div>" +
            "<div class='select2-result-repository__statistics d-flex font-small-3'>" +
            "<div class='select2-result-repository__forks mr-2'><b>" + helper.data('vat') + ":</b> " + product.vat + "</div>" +
            "<div class='select2-result-repository__stargazers mr-2'><b>" + helper.data('country') + ":</b> " + product.country + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('city') + ":</b> " + product.city + "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatPartnerSelection = function (product) {
        return product.name || product.text;
    };

    window.getDigits = function () {
        let currency = $('#currency option:selected').data('digits');
        let digits = $('#digits').val();

        if (currency === undefined) {
            return digits;
        } else {
            return currency;
        }
    };

    window.getTableData = function () {
        let data = [];

        $('div.product-repeater .repeater-wrapper:not(:first)').each(function () {
            let element = $(this);

            if (element.find('[aria-describedby="product_id"]').val() !== null) {
                data.push({
                    "product_id": parseInt(element.find('[aria-describedby="product_id"]').val()),
                    "product_count": parseInt(element.find('[aria-describedby="product_count"]').val()) || 0,
                    "product_gross_price": parseFloat(element.find('[aria-describedby="product_gross_unit_price"]').val()) || 0,
                    "product_net_price": parseFloat(element.find('[aria-describedby="product_net_unit_price"]').val()) || 0,
                    "product_vat": parseInt(element.find('[aria-describedby="product_vat_rate"]').val()) || 0,
                    "product_type": parseInt(element.find('[aria-describedby="product_type"]').val()) || 0,
                    "product_container": parseInt(element.find('[aria-describedby="product_container"]').val()) || 0,
                    "product_barcode": element.find('[aria-describedby="product_barcode"]').val() || '',
                    "product_warranty": element.find('[aria-describedby="product_warranty"]').val() || ''
                });
            }
        });

        return data;
    };

    window.getAdditionalCosts = function () {
        let data = [];

        $('div.cost-repeater .repeater-wrapper:not(:first)').each(function () {
            let element = $(this);

            data.push({
                "name": element.find('[aria-describedby="cost_name"]').val() || '',
                "currency": element.find('[aria-describedby="cost_currency"]').val() || '',
                "price": parseFloat(element.find('[aria-describedby="cost_value"]').val()) || 0
            });
        });

        return data;
    };

    $('#partner_id').select2({
        ajax: {
            url: helper.data('partners-list'),
            dataType: 'json',
            delay: 1000,
            cache: true,
            type: 'post',
            async: true,
            language: html.attr('lang'),
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select-option'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatPartner,
        templateSelection: formatPartnerSelection
    });

    $('#date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    $('.product-repeater').repeater({
        isFirstItemUndeletable: true,
        show: function () {
            $('.product-repeater .repeater-wrapper:not(:first)').removeClass('d-none');
            $(this).slideDown();

            $('.product-select:not(.select2-hidden-accessible)').select2({
                ajax: {
                    url: helper.data('product-list'),
                    language: html.attr('lang'),
                    dataType: 'json',
                    delay: 1000,
                    cache: true,
                    type: 'post',
                    async: true,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data, params) {
                        params.page = params.page || 1;

                        return {
                            results: data.items,
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    }
                },
                placeholder: helper.data('select-option'),
                escapeMarkup: function (markup) {
                    return markup;
                },
                minimumInputLength: 1,
                templateResult: formatProduct,
                templateSelection: formatProductSelection
            });

            if (feather) {
                feather.replace({width: 14, height: 14});
            }

            $('.currency').each(function () {
                $(this).text($('#currency').val());
            });

            $('[aria-describedby="product_warranty"]').flatpickr({
                dateFormat: "Y.m.d.",
                allowInput: true
            });
        },
        hide: function (deleteElement) {
            $(this).slideUp(deleteElement, function () {
                $(this).remove();
                updateControls();
            });
        }
    });

    $('.cost-repeater').repeater({
        isFirstItemUndeletable: true,
        show: function () {
            $('.cost-repeater .repeater-wrapper:not(:first)').removeClass('d-none');
            $(this).slideDown();

            if (feather) {
                feather.replace({width: 14, height: 14});
            }
        },
        hide: function (deleteElement) {
            $(this).slideUp(deleteElement, function () {
                $(this).remove();
                updateControls();
            });
        }
    });

    $("#saveButton").on("click", onSaveButton_45f4fbef);
    $('#currency').on('change', onCurrencyChange_45f4fbef);
    $("#invoice-based-stock-input-form").on('submit', onSubmitForm_45f4fbef);

    $(document).on("select2:select", "#partner_id", onDocumentPartnerSelect_45f4fbef);
    $(document).on("select2:select", '[aria-describedby="product_id"]', onDocumentProductSelect_45f4fbef);
    $(document).on('input change', '.gross-price-control', onChangeGrossPrice_45f4fbef);
    $(document).on('input change', '.net-price-control', onChangeNetPrice_45f4fbef);
    $(document).on('input change', '[aria-describedby="product_vat_rate"], [aria-describedby="product_count"]', onChangeVatRate_45f4fbef);

    if (helper.data('investors') === 1) {
        $('#investor_group').on('change', onInvestorChange_45f4fbef);
    }
}

export function unloadInvoiceBasedInputPage() {
    delete window.flatpickr;
    delete window.select2;
    delete window.formatPartnerSelection;
    delete window.formatPartner;
    delete window.formatProductSelection;
    delete window.formatProduct;
    delete window.getTableData;
    delete window.getAdditionalCosts;
    delete window.getDigits;

    $('.flatpickr-calendar').remove();
    $('#partner_id').select2('destroy');

    $("#saveButton").off("click", onSaveButton_45f4fbef);
    $('#currency').off('change', onCurrencyChange_45f4fbef);
    $("#invoice-based-stock-input-form").off('submit', onSubmitForm_45f4fbef);

    $(document).off("select2:select", "#partner_id", onDocumentPartnerSelect_45f4fbef);
    $(document).off("select2:select", '[aria-describedby="product_id"]', onDocumentProductSelect_45f4fbef);
    $(document).off('input change', '.gross-price-control', onChangeGrossPrice_45f4fbef);
    $(document).off('input change', '.net-price-control', onChangeNetPrice_45f4fbef);
    $(document).off('input change', '[aria-describedby="product_vat_rate"], [aria-describedby="product_count"]', onChangeVatRate_45f4fbef);

    if ($('#helper').data('investors') === 1) {
        $('#investor_group').off('change', onInvestorChange_45f4fbef);
    }
}